// ProtectedArea.js
import React from 'react';
import './ProtectedArea.css'; // Ensure you have the appropriate CSS file

const ProtectedArea = () => {
  return (
    <div className="protected-area">
      <h1 className="heading">Welcome to the Ethereum Bundler App!</h1>
      <p className="subheading">
        <b>Fees:</b><br />
        Launch + up to 30 buys: 0.2 ETH<br /><br />
        <b>Notes:</b><br />
        - Fees will be deducted within the bundle, so you only pay fees if the bundle executes.<br />
        - Fees are paid to Flashbots Bundle Provider.<br />
        - The use of the Bundle App is free.<br />
        - 30+ buy bundler coming soon.<br />
        - The bundle will not add liquidity; you have to add manually or it should be added automatically with the OpenTrading Function.
      </p>

      <div className="input-group">
        <label>
          <b>Token Contract Address:</b>
          <button className="info-button" title="Information about Token Address">?</button>
        </label>
        <input type="text" className="input-field" placeholder="Enter token contract address" />
      </div>

      <div className="input-group">
        <label>
        <b>Token Launch Method ID:</b>
          <button className="info-button" title="Information about Token Launch ID">?</button>
        </label>
        <input type="text" className="input-field" placeholder="4 Bytes Launch Method ID" />
      </div> 

      <div className="input-group">
        <label>
        <b>Deployer Private Key:</b>
          <button className="info-button" title="Information about Deployer">?</button>
        </label>
        <input type="text" className="input-field" placeholder="Enter token symbol" />
      </div>

      <div className="input-group">
        <label>
        <b>Bundle Wallets Keys:</b>
          <button className="info-button" title="Information about Bundle Wallets">?</button>
        </label>
        <textarea className="input-field" placeholder="1 Wallet Key in each line"></textarea>
      </div>

      <div className="input-group">
        <label>
        <b>Token to snipe per wallet (%):</b>
          <button className="info-button" title="Information about Tokens to snipe per wallet:">?</button>
        </label>
        <input type="text" className="input-field" placeholder="% of supply that is going to be snipe by each wallet eg. 1%" />
      </div>

      <div className="input-group">
        <label>
        <b>Initial ETH LP:</b>
          <button className="info-button" title="Information about Initial ETH LP:">?</button>
        </label>
        <input type="text" className="input-field" placeholder="1 ETH" />
      </div>

      <div className="input-group">
        <label>
        <b>Initial Token LP (%):</b>
          <button className="info-button" title="Information about Initial Token LP::">?</button>
        </label>
        <input type="text" className="input-field" placeholder="% of supply that s going to LP. eg. 70%" />
      </div>

      <button className="button">Simulate</button>
      </div>
  );
};

export default ProtectedArea;
